
import {Route,Routes } from 'react-router-dom';
import './App.css';
import MainComponent from './Component/MainComponent'
import Registration from './Component/Registration';
import Contact from './Component/Contact';
import Organizing from './Component/Organizing';
import CallForPaper from './Component/CallForPaper';
import PageNotFound from './Component/PageNotFound';
import ImportantDate from './Component/ImportantDate';
import Patrons from './Component/Patrons';
import TechnicalPro from './Component/TechnicalPro';
import PaperSubmission from './Component/PaperSubmission';
import ConferenceTrack from './Component/ConferenceTrack';

function App() {
  return (
    <Routes>
        <Route path="/" element={ <MainComponent/> } />
      
        <Route path="registration" element={ <Registration /> } />
        <Route path="/registration" element={ <Registration /> } />

        <Route path="contact" element={ <Contact /> } />
        <Route path="/contact" element={ <Contact /> } />

        <Route path="organizing" element={ <Organizing /> } />
        <Route path="/organizing" element={ <Organizing /> } />

        <Route path="callforpaper" element={ <CallForPaper /> } />
        <Route path="/callforpaper" element={ <CallForPaper /> } />

        <Route path="importantdate" element={ <ImportantDate /> } />
        <Route path="/importantdate" element={ <ImportantDate /> } />

        <Route path="patrons" element={ <Patrons /> } />
        <Route path="/patrons" element={ <Patrons /> } />

        <Route path="technicalpro" element={ <TechnicalPro /> } />
        <Route path="/technicalpro" element={ <TechnicalPro /> } />

        <Route path="papersubmission" element={ <PaperSubmission /> } />
        <Route path="/papersubmission" element={ <PaperSubmission /> } />

        <Route path="conferencetracks" element={ <ConferenceTrack /> } />
        <Route path="/conferencetracks" element={ <ConferenceTrack /> } />

        

        <Route path="*" element={ <PageNotFound /> } />
    </Routes>
  )
}

export default App;
